import React from "react"
import { graphql, navigate } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./font-awesome"
import moment from "moment"

import Layout from "./layout"
import SEO from "./seo"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronLeft,
  faDollarSign,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share"
import ReactMarkdown from "react-markdown"

const agenda = ({ data }) => {
  let shareUrl = ""
  if (typeof window !== "undefined") {
    shareUrl = window.location.href
  }
  return (
    <Layout>
      <SEO title={`${data.allStrapiDiaries.nodes[0].title} - ICDA`} />
      <section className="mb-3">
        {data.allStrapiDiaries.nodes.map(document => (
          <div className="container">
            <div className="my-4">
              <div className="d-flex">
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-link ml-auto mr-n2"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />{" "}
                  Volver
                </button>
              </div>
              <div className="bg-main px-2 py-1 text-white mb-3 fit-content">
                {document.diary_category?.name}
              </div>
              <h4 className="mb-2">{document.title}</h4>
              <div className="d-flex align-items-center flex-wrap mb-3">
                <h6 className="text-muted mb-0">
                  <small>Publicado: {document.published_at}</small>
                </h6>
                <div className="d-flex ml-auto">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={document.title}
                    className="mr-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={document.title}
                    className="mr-1"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl} className="mr-1">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
              {document.headerImages?.image.url ? (
                <div className="row">
                  <div className="col-12 col-md-10 col-lg-9">
                    <img
                      src={document.headerImages.image.url}
                      className="w-100 mb-3"
                      alt="Agenda"
                    />
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-12 col-md-8">
                  <ReactMarkdown className="text mb-4">
                    {document.content}
                  </ReactMarkdown>
                </div>
                <div className="col-12 col-md-4">
                  <div className="w-100 bg-light px-4 pt-4 pb-1">
                    <div className="row mb-3">
                      <div className="col-2 text-center">
                        <FontAwesomeIcon icon={faCalendar} size="lg" />
                      </div>
                      <div className="col-10">
                        <p className="font-weight-bold">
                          Fecha: {document.date} 
                          {document.time != null &&
                          " - " + document.time.substring(0, 5) + " hs" }
                        </p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 text-center">
                        <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                      </div>
                      <div className="col-10">
                        <p className="font-weight-bold">
                          Lugar: {document.place}
                        </p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 text-center">
                        <FontAwesomeIcon icon={faDollarSign} size="lg" />
                      </div>
                      <div className="col-10">
                        <p className="font-weight-bold">{document.cost}</p>
                      </div>
                    </div>
                    {document.url ? (
                      <a
                        href={document.url}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-outline-primary mb-3"
                      >
                        INSCRIBIRME
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap py-4">
                <h4 className="flex-grow-1 flex-grow-1 mb-2">
                  COMPARTILO CON TU RED
                </h4>
                <div className="d-flex mb-2">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={document.title}
                    className="mr-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={document.title}
                    className="mr-1"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl} className="mr-1">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export default agenda

export const agendaQuery = graphql`
  query($id: String) {
    allStrapiDiaries(filter: { id: { eq: $id } }) {
      nodes {
        content
        cost
        date(formatString: "DD/MM/YYYY", locale: "es")
        time
        place
        title
        summary
        strapiId
        id
        url
        headerImages {
          image {
            id
            url
          }
        }
        diary_category {
          name
        }
        published_at(formatString: "DD MMMM, YYYY", locale: "es")
      }
    }
  }
`
